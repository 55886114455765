<template>
    <complaints-overview
        :grid-url="url"
        :grid-options="gridOptions"
        :get-complaint-detail-route="getComplaintDetailRoute"
        :get-complaint-detail-parent-route="getComplaintDetailParentRoute"
        :is-global-overview="true"
        ref="complaintsOverview"
    >
        {{complaintSection}}
    </complaints-overview>
</template>

<script>
import CONFIG from '@root/config'
import ComplaintsOverview from '../supplier/complaints/ComplaintsOverview'
import moment from 'moment'
import { secondsToDaysHoursMinutesAndSeconds } from '../../components/utils/DateTimeUtils'
import { addBusinessDaysFromHours } from '../../components/utils/Utils'

const now = moment()

export default {
    name: 'ComplaintsGlobalOverview',
    components: { ComplaintsOverview },
    props: {
        complaintSection: {
            type: String,
        },
        complaintStatus: {
            type: String,
        },
    },
    data () {
        return {
            gridOptions: {
                columns: {
                    'Time': {
                        field: 'dateCreated',
                        valueFormatter ({ value, data }) {
                            if (data.dateCreated !== null) {
                                return moment(value).format('L LT')
                            } else {
                                return '-'
                            }
                        },
                    },
                    'Supplier': {
                        field: 'supplierName',
                        link: (supplierName, data) => {
                            return {
                                name: 'SupplierView',
                                params: {
                                    supplierId: data.supplierId,
                                },
                            }
                        },
                    },
                    'Order': {
                        field: 'orderNumber',
                        valueGetter: ({ data }) => `${data.orderNumber} - ${data.orderLineNumber}`,
                    },
                    'Status': {
                        field: 'complaintStatus',
                        colId: 'complaintStatus',
                        statusChip: true,
                        valueGetter: ({ data }) => {
                            return this.$options.filters.beautify(data.complaintStatus)
                        },
                    },
                    'Type': {
                        field: 'complaintType',
                        valueGetter: ({ data }) => data.complaintType || '-',
                    },
                    'Costs': {
                        field: 'complaintCosts',
                        valueGetter ({ data, context }) {
                            const supplierComplaintCosts = Object.keys(data.supplierComplaintCosts).length !== 0 ? data.supplierComplaintCosts : data.complaintCosts
                            return context.$options.filters.asMoney(data.complaintCosts.value, data.complaintCosts.currency) + ' / ' + context.$options.filters.asMoney(supplierComplaintCosts.value, supplierComplaintCosts.currency)
                        },
                    },
                    'Assigned agent': {
                        valueGetter: ({ data }) => {
                            for (let i = 0; i < data.virtualSupplierComplaintNotes.length; i++) {
                                const account = this.getAccountByAccountId(data.virtualSupplierComplaintNotes[i].uploadedBy)
                                if (account) {
                                    return `${account.firstName} ${account.lastName}`
                                }
                            }

                            return ''
                        },
                    },
                    'Shared with supplier': {
                        field: 'shareWithSupplier',
                        valueGetter ({ data }) {
                            return data.shareWithSupplier ? 'Yes' : 'No'
                        },
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        valueFormatter ({ value }) {
                            return moment(value).format('L LT')
                        },
                    },
                    'Latest comment': {
                        valueGetter: ({ data }) => {
                            if (!data.latestNote) {
                                return ''
                            }
                            const account = this.getAccountByAccountId(data.latestNote.uploadedBy)

                            return (account ? `${account.firstName} ${account.lastName}:` : '') + data.latestNote.note
                        },
                        field: 'latestNote.note',
                    },
                    'SLA': {
                        colId: 'slaTime',
                        valueGetter ({ data }) {
                            if (!data.dateShared) {
                                return null
                            }

                            return addBusinessDaysFromHours(data.slaHours, data.dateUpdated).diff(now) / 1000 / 60 / 60
                        },
                        valueFormatter ({ value }) {
                            let timeLeft = secondsToDaysHoursMinutesAndSeconds(value * 60 * 60)
                            delete timeLeft.s

                            return (value < 0 ? '-' : '') + Object.entries(timeLeft).map(([unit, value]) => `${value}${unit}`).join(' ')
                        },
                        cellClass ({ value }) {
                            if (value < 0) {
                                return 'error'
                            }
                            if (value < 1) {
                                return 'warning'
                            }

                            return 'green'
                        },
                    },
                },

                quickSearchColumns: ['orderNumber'],

                sortModel: [{
                    colId: 'dateUpdated',
                    sort: 'desc',
                }],
                actions: [
                    {
                        text: 'Allow',
                        tooltipText: 'Allow this complaint',
                        icon: 'fas fa-check',
                        onClick: ({ data }) => {
                            this.allowComplaint(data)
                        },
                        hide: ({ data }) => data.shareWithSupplier,
                    },
                    {
                        text: 'Allow + future complaints',
                        tooltipText: 'Allow and future complaints from this application',
                        icon: 'fas fa-check-double',
                        onClick: ({ data }) => {
                            this.allowComplaintAndAllowFuture(data)
                        },
                        hide: ({ data }) => data.shareWithSupplier || !this.isAllowedTo('SupplierCentral/addComplaintAllowedApplication'),
                    },
                ],
            },
            filters: {
                search: '',
            },
            accounts: [],
        }
    },
    computed: {
        url () {
            return CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.OVERVIEW_VIRTUAL_SUPPLIER
                .replace('{complaintSection}', this.complaintSection)
                .replace('{complaintStatus}', this.complaintStatus)
        },
    },
    methods: {
        getAccountByAccountId (accountId) {
            return this.accounts.find(account => account.accountId === accountId)
        },
        getComplaintDetailRoute (complaint) {
            return {
                name: 'ComplaintGlobalDetail',
                params: {
                    complaintId: complaint.complaintId,
                    complaintSupplierId: complaint.supplierId,
                },
            }
        },
        getComplaintDetailParentRoute () {
            return {
                name: 'ComplaintsGlobalOverview',
            }
        },
        allowComplaint (complaint) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.UPDATE
                .replace('{supplierId}', complaint.supplierId)
                .replace('{complaintId}', complaint.complaintId)
            this.addJob(url)
            complaint.shareWithSupplier = true

            return this.$api.post(url, complaint).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Complaint successfully shared.`,
                })
                this.$refs.complaintsOverview.$refs.grid.refreshGrid()
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Sharing complaint failed`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
        allowComplaintAndAllowFuture (complaint) {
            this.allowComplaint(complaint).then(() => {
                const url = CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.ALLOWED_APPLICATIONS.ADD
                this.addJob(url)

                return this.$api.post(url, {
                    applicationId: complaint.applicationId,
                    organisationId: complaint.organisationId,
                }).then(() => {
                    this.$root.$emit('notification:global', {
                        message: `Application allowed for future complaints.`,
                    })
                }).catch(data => {
                    this.$root.$emit('notification:global', {
                        message: `Allowing application failed`,
                        type: 'error',
                        errors: data,
                    })
                }).finally(() => {
                    this.loading = false
                    this.finishJob(url)
                })
            })
        },
    },
    created () {
        const url = CONFIG.API.ROUTES.ACCESS_CONTROL.OVERVIEW_FOR_ORGANISATION.replace('{organisationId}', this.$store.state.auth.user.organisationId)
        this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.accounts = data.data.items
        })
    },
}
</script>
